<template>
  <b-modal
    id="timezone-new-modal-id"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ block.id ? $t('components.custom.playground.new.step-2.timezone.update') : $t('components.custom.playground.new.step-2.timezone.title') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <new-timezone-form
      :block="block"
      :model="model"
    />
    <b-row
      class="mt-3"
    >
      <b-col cols="1"></b-col>
      <b-col class="pr-0" align="right">
        <d-button
          text="general.actions.save"
          class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
          @on:button-click="onBlockSave"
        />
      </b-col>
      <b-col cols="1"></b-col>
    </b-row>
  </b-modal>
</template>
<script>

import {postBlock, putBlock} from "@api/doinsport/services/timetable/blocks/block.api";
import Block from "@/classes/doinsport/Block";

export default {
  components: {
    NewTimezoneForm: () => import('@form/playground/new/step-2/NewTimezoneForm')
  },
  data: () => ({
    btnSwitch: true,
  }),
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    timetable: {
      type: Object,
      default: this,
    },
    model: {
      type: Object,
      default: this,
    },
    block: {
      type: Object,
      default: this,
    }
  },
  watch: {
    display: function (changes) {
      this.$bvModal.show('timezone-new-modal-id');
    }
  },
  mounted() {
    this.$bus.$on('on:step-2-new-timezone-modal:validated', () => {

      let serializedBlock = new Block(this.block, {serialize: true});
      serializedBlock.timetable = this.timetable.iri;

      const startAt = this.$moment()
        .tz(this.$store.getters["auth/currentClub"].timezone)
        .hour(parseInt(this.$moment.utc(serializedBlock.startAt, 'HH:mm').format('HH')))
        .minute(parseInt(this.$moment.utc(serializedBlock.startAt, 'HH:mm').format('mm')))
      ;
      const endAt = this.$moment()
        .tz(this.$store.getters["auth/currentClub"].timezone)
        .hour(parseInt(this.$moment.utc(serializedBlock.endAt, 'HH:mm').format('HH')))
        .minute(parseInt(this.$moment.utc(serializedBlock.endAt, 'HH:mm').format('mm')))
      ;

      serializedBlock.startAt = startAt.format('HH:mm');
      serializedBlock.endAt = endAt.format('HH:mm');

      let validMaxParticipantsCount = true;

      if (serializedBlock.maxParticipantsCountLimit === '') {
        serializedBlock.maxParticipantsCountLimit = null;
      } else {
        if (serializedBlock.maxParticipantsCountLimit !== null) {
          serializedBlock.maxParticipantsCountLimit = parseInt(serializedBlock.maxParticipantsCountLimit);

          if (parseInt(serializedBlock.maxParticipantsCountLimit) === 0) {
            validMaxParticipantsCount = false;
          }
        }
      }
      if (validMaxParticipantsCount) {
        if (serializedBlock.id === null) {
          postBlock(serializedBlock)
            .then((response) => {
              this.$bus.$emit('refresh-timetables');
            })
            .finally(() => {
              this.$bvModal.hide('timezone-new-modal-id');
            })
          ;
        } else {
          delete serializedBlock.prices;
          putBlock(serializedBlock)
            .then((response) => {
              this.$bus.$emit('refresh-timetables');
            })
          ;
        }
      }
    })
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:step-2-new-timezone-modal:validated');
    } catch (e) {
    }
  },
  methods: {
    checkInput(name) {
      if (name === null || name === "") {
        return false;
      } else if (name.length < 3) {
        return false;
      } else {
        return true;
      }
    },
    checkTime(time) {
      if (time === null) {
        return false;
      } else if (time.HH === "") {
        return false;
      } else if (time.mm === "") {
        return false
      } else {
        return true;
      }
    },
    onBlockSave() {
      this.$bus.$emit('on:step-2-new-timezone-modal:validate', {
        name: this.checkInput(this.block.name),
        startAt: this.checkTime(this.block.startAt),
        endAt: this.checkTime(this.block.endAt)
      });
    }
  }
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 88px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
